// Inline code
code {
    padding: 0.1rem 0.4rem;
    font-size: 90%;
    color: $code-color;
    background-color: $kbd-bg;
    @include border-radius($border-radius/2);
}
pre {
    background-color: #f7f7f9;
    code {
        background-color: transparent !important;
    }
}

