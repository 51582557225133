// body {
//     background-color: white;
// }
.spinnerContent {
    display: flex;
    justify-content: center;
    margin: 120px 0 0 40px;
}
.spinners {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99999;
    height: 50px;
    width: 50px;
    transform: translate(-50%, -50%);
}

.ball-1,
.ball-2,
.ball-3,
.ball-4,
.ball-5,
.ball-6,
.ball-7,
.ball-8 {
    position: absolute;
    display: block;
    left: 30px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    transition: all 0.5s;
    animation: circleRotate 5s both infinite;
    transform-origin: 0 250% 0;
}

@keyframes circleRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(1440deg);
    }
}

.ball-1 {
    z-index: -1;
    background-color: #16285f;
    animation-timing-function: cubic-bezier(0.5, 0.3, 0.9, 0.9);
}

.ball-2 {
    z-index: -2;
    background-color: #16285f;
    animation-timing-function: cubic-bezier(0.5, 0.6, 0.9, 0.9);
}

.ball-3 {
    z-index: -3;
    background-color: #16285f;
    animation-timing-function: cubic-bezier(0.5, 0.9, 0.9, 0.9);
}

.ball-4 {
    z-index: -4;
    background-color: #16285f;
    animation-timing-function: cubic-bezier(0.5, 1.2, 0.9, 0.9);
}

.ball-5 {
    z-index: -5;
    background-color: #fce128;
    animation-timing-function: cubic-bezier(0.5, 1.5, 0.9, 0.9);
}

.ball-6 {
    z-index: -6;
    background-color: #fce128;
    animation-timing-function: cubic-bezier(0.5, 1.8, 0.9, 0.9);
}

.ball-7 {
    z-index: -7;
    background-color: #fce128;
    animation-timing-function: cubic-bezier(0.5, 2.1, 0.9, 0.9);
}

.ball-8 {
    z-index: -8;
    background-color: #fce128;
    animation-timing-function: cubic-bezier(0.5, 2.4, 0.9, 0.9);
}
