.pagnation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* margin: 0px 0 40px 0px; */
}
.pagnation span {
    font: normal normal bold 16px/19px Rubik;
    letter-spacing: 0px;
    color: #16285e;
}
.spinerTop {
    margin-top: -110px;
}
.paginateContent {
    position: absolute !important;
    right: 0;
    bottom: -85px;
}
.showPagnation {
    width: 130px !important;
    height: 40px;
    margin: 0 5px 0 20px;
    box-shadow: 0px 0px 3px #00000029;
    color: #16285e !important;
    border-radius: 50px !important;
    cursor: pointer;
}
.optionShow {
    font: normal normal normal 15px/18px Rubik;
    letter-spacing: 0px;
    color: #16285e !important;
}
.optionShow span {
    font: normal normal normal 15px/18px Rubik;
    letter-spacing: 0px;
    color: #16285e;
}
.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 6px;
    margin: 5px;
    cursor: pointer;
}
.arrow img {
    width: 6px;
}
/*  */

.reactPaginate {
    display: flex;
    margin-top: 15px;
    padding-left: 1rem !important;
}
.reactPaginate li {
    list-style-type: none;
    color: #16285e;
    margin: 0 5px;
}
.selected {
    background-color: #16285e;
    color: white !important;
    padding: 6px 13px;
    border-radius: 50px;
}

.reactPaginate li {
    display: flex;
    align-items: center;
}
.reactPaginate li:first-child,
.reactPaginate li:last-child {
    background-color: white;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 50px;
    padding: 6px 14px;
    margin-top: 0px !important;
}

/* **************************************** */
#mui-1 {
    padding-left: 0rem !important;
}
.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
    margin-bottom: 0rem !important;
}
@media (max-width: 480px) {
    .pagnation span {
        font: normal normal bold 14px/19px Rubik;
    }
    .showPagnation {
        margin: 8px;
        width: 100px;
    }
    .reactPaginate li {
        display: none;
    }
    .reactPaginate .previous,
    .reactPaginate .next {
        display: flex !important;
    }
    .reactPaginate {
        padding-left: 0rem !important;
    }
}
@media (max-width: 560px) {
    .showPagnation {
        width: 116px !important;
    }
    .ReactModal__Content {
        width: 300px !important;
    }
}
